import LoadLight from '@src/lib/lottieData/Loading.json';
import lottie from 'lottie-web';
import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

const Loading = (children: React.ReactNode) => {
  return <span>{children}</span>;
};

const LottieLoading = () => {
  const router = useRouter();

  const load: any = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: load.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: LoadLight,
    });
  }, []);

  // 마케팅용 크리테오 피드 페이지에서는 로딩 컴포넌트 제거
  if (router.asPath === '/thirdParty/feed/data') {
    return null;
  }

  return (
    <div
      id="topcoLoading"
      className="fixed flex items-center justify-center w-[100vw] h-[70vh] bg-[#ffffff0d] z-[9]"
    >
      <span className="block -mt-[2px] ml-[6px] w-[400px] mo:w-[200px]">
        <div ref={load} />
      </span>
    </div>
  );
};

Loading.LottieLoading = LottieLoading;
export default Loading;

export namespace TopcoLoadingController {
  export const hide = () => {
    if (!document) return;

    const dom = document.getElementById('topcoLoading') as HTMLElement;
    if (dom) {
      dom.style.opacity = '0';
      dom.style.transition = 'all ease-in-out 0.5s';
      setTimeout(function () {
        dom.style.display = 'none';
      }, 800);
    }
  };

  export const show = () => {
    if (!document) return;
    const dom = document.getElementById('topcoLoading') as HTMLElement;
    if (dom) {
      dom.style.opacity = '0.5';
      dom.style.transition = 'all ease-in-out 0.5s';
      dom.style.display = 'flex';
    }
  };
}
